//= require slick-carousel/slick/slick.js

$(function() {
  var conf = {
    testimonies: {
      slidesToShow: 1,
      slidesToScroll: 1
    },
    testimonies_collaborators: {
      slidesToShow: 1,
      slidesToScroll: 1
    }
  };

  $('.c-slick').each(function() {
    // return;
    var $this = $(this);

    var component = $this.data('slick-component');
    var $component = $this.closest('.c-' + component);
    var slick_class = 'c-' + component + '--slick';
    if ($component.hasClass(slick_class)) return;
    $component.addClass(slick_class);

    $this.slick($.extend({
      dots: false,
      infinite: false,
      speed: 300,
      prevArrow: $component.find('.c-slick__prev'),
      nextArrow: $component.find('.c-slick__next'),
    }, conf[component.replace('-', '_')]));


    if (component.indexOf('testimonies') !== -1) {
      $this.on('beforeChange',  function(event, slick, currentSlide, nextSlide) {
        nextSlide += 1;
        $component.find('.c-slick__number span').text(nextSlide);
      });
    }
  });
});
