//= require skrollr/src/skrollr.js

(function($) {
  $(function() {
    var s = skrollr.init({
      mobileCheck: function() {
        return false;
      }
    });
  });
})(jQuery);
