(function($) {
  $(function() {
    $('.js-form-field--subject select').on('change', function() {
      var val = String($(this).val()).toLowerCase();

      $('.js-form-group--company, .js-form-group--postal_code, .js-form-group--curriculum').hide().find('input, select, textarea').prop('disabled', true);

      if (val.indexOf('trouver') !== -1) {
        $('.js-form-group--postal_code').show().find('input, select, textarea').prop('disabled', false);
      }
      else if (val.indexOf('postuler') !== -1 || val.indexOf('rejoindre') !== -1) {
        $('.js-form-group--curriculum').show().find('input, select, textarea').prop('disabled', false);
      }
      else if (val.indexOf('collaborer') !== -1) {
        $('.js-form-group--company').show().find('input, select, textarea').prop('disabled', false);
      }
      else {

      }
    }).trigger('change');
  });
})(jQuery);
